import * as TreeViewHelper from './treeview_helper'
import * as JourneyHelper from './journey_helper'
import * as ReportsHelper from './reports_helper'
import * as DesignerHelper from './designer_helper'
import * as ZIndexHelper from './zindex_helper'
import * as UnitFormHelper from './units/form'
import * as UnitLogBookHelper from './units/log_book'
import * as DesignerFieldsFITBHelper from './designer/fields/fitb_helper' 
import * as GradingSchemeHelper from './grading_scheme_helper'
import * as ActivityTemplatesHelper from './activity_templates_helper'

// Helpers
export namespace Helpers {
  export const TreeView = TreeViewHelper;
  export const Journey = JourneyHelper;
  export const Reports = ReportsHelper;
  export const Designer = DesignerHelper;
  export const ZIndex = ZIndexHelper;
  export const UnitForm = UnitFormHelper;
  export const UnitLogBook = UnitLogBookHelper;
  export const DesignerFieldsFITB = DesignerFieldsFITBHelper;
  export const GradingScheme = GradingSchemeHelper;
  export const ActivityTemplates = ActivityTemplatesHelper;

  Designer.init()
  Reports.init()
  UnitForm.init()

  export function objectToQueryString(obj: Record<string, any>): string {
    return Object.entries(obj)
      .flatMap(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
        }

        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');
  }
}