import { Tooltip } from 'bootstrap';
export class TooltipsListener{

  constructor() {
    this.init();
  }

  public init(){
    document.addEventListener('turbo:load', this.tooltipInit);
    document.addEventListener('load', this.tooltipInit);
    document.addEventListener('turbo:visit', this.tooltipInit);
    document.addEventListener("table-filtered", this.tooltipInit);
  }

  private tooltipInit(){
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }
}
