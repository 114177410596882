export function deleteLogItem(element) {
  let row = element.parentElement

  row.classList.add('d-none')
  row.querySelector('.sortable-item').classList.add('ignore-sortable')
  element.querySelector('input').value = '1'

  sortField()
}

export function addLogItem(event, element) {
  event.preventDefault()

  let ele = element.querySelector('.aff')
  let time = `${ new Date().getTime() }${ Math.floor((Math.random() * 1000000)) }`
  let regexp = new RegExp(ele.dataset.id, 'g')
  let new_field = ele.dataset.fields.replace(regexp, time)

  element.previousElementSibling.insertAdjacentHTML('beforeend', new_field)

  sortField()
}

function sortField() {
  document.getElementById('log_items').querySelectorAll('.sortable-item:not(.ignore-sortable)').forEach((item, index) => {
    item.querySelector('.position').value = index + 1
  })
}
