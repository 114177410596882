export function init() {
  handleOffcanvasTableReloadForQualiToUnitForm()
  handleOffcanvasTagsInputRemoveItemForQualiToUnitForm()
}

export function toggleActiveSwitch(event){
  const qualification_id = event.target.closest('.table__row').querySelector('input[name="qualification"][type="checkbox"]').value

  document.getElementById(`qualiToUnitActive--${qualification_id}`).value = event.target.checked;
}

function handleOffcanvasTableReloadForQualiToUnitForm() {
  let currentTableCheckboxes = [];

  document.addEventListener('offcanvas-table-reload', (event) => {
    setTimeout(() => {
      const qualiToUnitContainer = document.getElementById('qualiToUnitActiveContainer')
      if (!qualiToUnitContainer) return;

      const checkboxes = event.target.querySelectorAll('input[type="checkbox"]:not(.switch')

      checkboxes.forEach(checkbox => {
        currentTableCheckboxes.forEach((checkbox) => checkbox.removeEventListener('click'))

        const activeHiddenInput = document.getElementById(`qualiToUnitActive--${checkbox.value}`)
        const activePropertySwitch = checkbox.closest('.table__row').querySelector('.switch')

        if (checkbox.checked && activePropertySwitch) {
          activePropertySwitch.disabled = false;
          
          if (activeHiddenInput) activePropertySwitch.checked = activeHiddenInput.value == 'true';
        }

        checkbox.addEventListener('click', (event) => {
          if (event.target.checked) {
            if(activePropertySwitch) activePropertySwitch.disabled = false;

            const newActive = (activeHiddenInput || createActivePropertyForNewQualiToUnit(checkbox.value))
                  newActive.value = 'true'

            const destroyHiddenInput = newActive.parentElement.querySelector('.destroy-quali-to-unit')
            destroyHiddenInput.value = false;
          } else {
            const activeInput = document.getElementById(`qualiToUnitActive--${checkbox.value}`)

            if(activePropertySwitch) {
              activePropertySwitch.checked = true;
              activePropertySwitch.disabled = true;  
            }

            const destroyHiddenInput = activeInput.parentElement.querySelector('.destroy-quali-to-unit')

            if (destroyHiddenInput.dataset.new == 'true') {
              activeInput.parentElement.remove();
            } {
              destroyHiddenInput.value = true;
            }
          }
        })
      })
    }, 100)
  })
}

function handleOffcanvasTagsInputRemoveItemForQualiToUnitForm() {
  document.addEventListener('tag-removed-global', (event) => {
    const qualiToUnitContainer = document.getElementById('qualiToUnitActiveContainer')
    if (!qualiToUnitContainer) return;

    const checkbox = document.querySelector(`input[type="checkbox"][value="${event.detail.params.tagValue}"]`)

    if (checkbox) {
      const activePropertySwitch = checkbox.closest('.table__row').querySelector('.switch')
      if (activePropertySwitch) {
        activePropertySwitch.checked = true;
        activePropertySwitch.disabled = true;
      }
    }

    const activeHiddenInput = document.getElementById(`qualiToUnitActive--${event.detail.params.tagValue}`)
    const destroyHiddenInput = activeHiddenInput.parentElement.querySelector('.destroy-quali-to-unit')

    if (destroyHiddenInput.dataset.new == 'true') {
      activeHiddenInput.parentElement.remove();
    } {
      destroyHiddenInput.value = true;
    }
  })
}

function createActivePropertyForNewQualiToUnit(qualification_id) {
  const div = document.createElement('div');

  const createHiddenInput = (name) => {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const input = document.createElement('input');

    input.type  = 'hidden';
    input.name  = `unit[qualification_to_units_attributes][${unixTimestamp}][${name}]`;

    if(name == 'qualification_id') { 
      input.value = qualification_id
    } else if (name == '_destroy') { 
      input.classList = 'destroy-quali-to-unit';
      input.dataset.new = 'true'
      input.value = false;
    } else {
      input.id = `qualiToUnitActive--${qualification_id}`;
    }

    return input;
  };

  const activeHiddenInput = createHiddenInput('active')
  const destroyHiddenInput = createHiddenInput('_destroy')
  const qualificationIdHiddenInput = createHiddenInput('qualification_id')

  div.appendChild(activeHiddenInput)
  div.appendChild(destroyHiddenInput)
  div.appendChild(qualificationIdHiddenInput)
  
  document.getElementById('qualiToUnitActiveContainer').appendChild(div)

  return activeHiddenInput;
}