// Journey Helper

export function enableStepReleaseTimePicker(event) {
  const self = event.target
  const timepicker = self.closest('div[data-target="switch.container"]')
                         .querySelector('input[data-controller="flatpickr"]')
  const timepickerInputGroup = timepicker.closest('.input-group')

  if (self.value < 1) {
    timepicker.value = ''
    timepickerInputGroup.classList.add('input-group--disabled')
    timepicker.disabled = true
  } else {
    if(timepicker.value) return;

    timepicker.value = '00:00'
    timepickerInputGroup.classList.remove('input-group--disabled')
    timepicker.disabled = false  
  }
}
