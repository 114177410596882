export class BrowserUserAgent {
  private userAgent: string;

  constructor() {
    this.userAgent = navigator.userAgent;
  }

  getBrowser(): string {
    if (this.userAgent.indexOf('Chrome') > -1) return 'Chrome';
    if (this.userAgent.indexOf('Safari') > -1) return 'Safari';
    if (this.userAgent.indexOf('Firefox') > -1) return 'Firefox';
    if (this.userAgent.indexOf('MSIE') > -1) return 'IE';
    if (this.userAgent.indexOf('Trident/') > -1) return 'IE';
    return 'unknown';
  }
}
