// Reports Helper

import { post, get } from '@rails/request.js'
import { Dropdown } from 'bootstrap'

export function openSaveModal(event) {
  const form = document.getElementById('reports_data_form')
  const formData = new FormData(form)
  const urlParams = new URLSearchParams(formData).toString()

  const btn = document.getElementById('create-report-btn')
  const url = `${form.action}${form.action.includes('?') ? '&' : '?'}${urlParams}`

  btn.href = url;
  btn.dataset['bridgeSourceParam'] = url;

  event.innerHTML.trim() == 'Create' ? btn.click() : post(url, { body: new FormData(form), responseKind: "turbo-stream"  })
}

export function openUnitsDropdown() {
  Dropdown.getInstance(document.getElementById('units-dropdown-toggle')).show()
}

export function previewReport(event) {
  const form = document.getElementById('reports_data_form')
  const formData = new FormData(form)
  const urlParams = new URLSearchParams(formData)
  urlParams.append('preview', 'true')

  get('/report_engine/reports/preview', { query: urlParams, responseKind: "turbo-stream" })
}

export function toggleControlls() {
  const previewButton = document.getElementById('preview-button')
  const exportCsv = document.getElementById('export-to-csv')
  const exportXlsx = document.getElementById('export-to-xlsx')
  const saveReportButton = document.getElementById('save-report-button')

  previewButton.disabled = false

  if (exportCsv)
    exportCsv.disabled = true
  if (exportXlsx)
    exportXlsx.disabled = true

  saveReportButton.disabled = true
}

export function enableSaveReport() {
  const saveReportButton = document.getElementById('save-report-button')
  saveReportButton.disabled = false
  saveReportButton.classList.remove('disabled')
}

export function init() {
  if (!['reports-new-v2', 'reports-show-v2'].includes(document.body.id)) return;

  initShowDropdown();
}

function initShowDropdown(){
  document.getElementById('show-dropdown')?.addEventListener('show.bs.dropdown', event => {
    const groupOption = document.querySelector('input[name="reports[groupping][type]"]:checked')
    const percentageToggleContainer = document.getElementById('report-show-percentage')
    
    percentageToggleContainer.style.display = groupOption?.value == 'members' ? 'none' : null
    if (groupOption?.value == 'members')
      percentageToggleContainer.querySelector('input').checked = false
  })  
}
