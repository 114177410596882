export function addGrandeRangeItem(event, element) {
  event.preventDefault()

  let ele = element.querySelector('.aff')
  let time = `${ new Date().getTime() }${ Math.floor((Math.random() * 1000000)) }`
  let regexp = new RegExp(ele.dataset.id, 'g')
  let new_field = ele.dataset.fields.replace(regexp, time)
  let gradingItems = element.previousElementSibling

  gradingItems.insertAdjacentHTML('beforeend', new_field)

  let rows = gradingItems.querySelectorAll('.row')
  if (rows.length == 1) {
    rows[0].querySelector('input').checked = true
  }
}

export function deleteLogItem(element) {
  let row = element.closest('.row')
  let gradingItems = element.closest('#grading_items')

  row.remove()

  validate(gradingItems)
}

export function onChange(event, element) {
  let row = element.closest('.row')
  const rowValue = parseFloat(element.value) || 0;

  if (rowValue < 0 || rowValue > 100) {
    addErrorMessage(row, 'The value must be between 0 and 100.')
    return
  } else {
    removeErrorMessage(row, 'The value must be between 0 and 100.')
  }

  let gradingItems = element.closest('#grading_items')
  
  validate(gradingItems)
}

export function onRadioClicked(element) {
  let gradingItems = element.closest('#grading_items')

  gradingItems.querySelectorAll('.row').forEach((row) => {
    row.querySelector('input[type="radio"]').checked = false
  })
  element.checked = true
}

function validate(gradingItems) {
  let rows = gradingItems.querySelectorAll('.row')
  let newErrorSpan = document.createElement('span');
  newErrorSpan.classList.add('error-hint');

  rows = Array.from(rows)

  rows.forEach((row, index) => {
    const rowValue = parseFloat(row.querySelector('.grading_scheme_ranges_data__min_range input').value) || 0;
    let errorMsg = ''
    rows.slice(index-1, index).forEach((prevRow) => {
      let prevRowValue = parseFloat(prevRow.querySelector('.grading_scheme_ranges_data__min_range input').value) || 0;
      if (prevRowValue <= rowValue) {
        errorMsg = `The value must be less than ${prevRowValue}.`
      }
    })
    if (errorMsg != '') {
      addErrorMessage(row, errorMsg)
    } else {
      removeErrorMessage(row, /^The value must be less than (\d+)\.$/)
    }
  })
}

function addErrorMessage(row, errorMsg) {
  let newErrorSpan = document.createElement('span');
  newErrorSpan.classList.add('error-hint');

  row.querySelector('.range-input div').classList.add('form-control--invalid')
  let errorSpan = row.querySelector('.range-input .error-hint')

  if (errorSpan) {
    errorSpan.textContent = errorMsg
  } else {
    newErrorSpan.textContent = errorMsg
    row.querySelector('.range-input div').appendChild(newErrorSpan)
  }
}

function removeErrorMessage(row, message) {
  let errorSpan = row.querySelector('.range-input .error-hint')
  
  if (errorSpan && errorSpan.textContent.match(message)) {
    row.querySelector('.range-input div').classList.remove('form-control--invalid')
    errorSpan.remove()
  }
}
