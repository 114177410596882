/**
 * Changes the z-index of a parent element and ensures the z-index is reset when a click is detected outside the parent.
 *
 * @param {HTMLElement} parent - The parent element which needs its z-index changed.
 * @param {number} zIndex - The z-index value to be set.
 * @param {HTMLElement} _self - The element that triggers the change.
 */

export function changeParentZIndex(parent: HTMLElement, zIndex: number, _self: HTMLElement) {
  if (!parent) return;

  parent.style.setProperty('z-index', zIndex.toString(), 'important');

  if (!parent.dataset.zIndexListenerAdded) {
    document.addEventListener('click', (event) => {
      if (!parent.contains(event.target as Node)) {
        parent.style.removeProperty('z-index');
      }
    });
    parent.dataset.zIndexListenerAdded = 'true';
  }
}
