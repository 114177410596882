// Treeview Helper

export function expandList(event) {
  const childList = event.parentNode.parentNode.querySelector('.list-treeview')

  if (!childList) return;

  if (childList.style.display == 'inherit') {
    childList.style.display = null
    event.style.transform = 'rotate(0deg)';
  } else {
    childList.style.display = 'inherit'
    event.style.transform = 'rotate(90deg)';
  }
}

export function checkLevel(event, selectChildren = false) {
  const membersView = document.body.id.includes('members')
  const level = event.closest('.list-treeview__item')

  level.querySelectorAll('.list-treeview input[type="checkbox"]').forEach((input) => {
    if (event == input) return;
        
    input.checked = membersView ? false : event.checked
    input.checked = selectChildren ? event.checked : input.checked
    input.disabled = event.checked

    if (selectChildren) {
      const childList = input.parentNode.parentNode.querySelector('.list-treeview')

      if (childList) {
        childList.querySelectorAll('input[type="checkbox"]').forEach((childInput) => {
          childInput.checked = event.checked
          childInput.disabled = event.checked
        })
      }
    }
  })

  document.querySelectorAll('.list-treeview .ca-dot').forEach((i) => i.remove())

  const checkedInputs = document.querySelectorAll('.list-treeview input[type="checkbox"]:checked:not([disabled]');

  checkedInputs.forEach(checkbox => {
    let parent = checkbox.closest('.list-treeview__item').parentElement.closest('.list-treeview__item');

    while (parent) {
      const parentLabel = parent.querySelector('label')

      if (parentLabel && !parentLabel.querySelector('.ca-dot')) {
        const newSpan = document.createElement('span')
        newSpan.className = 'ca-dot ca-dot--info'

        parentLabel.appendChild(newSpan)
      }

      parent = parent.parentElement.closest('.list-treeview__item')
    }
  })
}

export function reset() {
  const treeview = document.querySelector('.list-treeview')
  document.querySelectorAll('.list-treeview .ca-dot').forEach((i) => i.remove())

  treeview.querySelectorAll('.list-treeview input[type="checkbox"]').forEach((input) => { 
    input.checked = false
    input.disabled = false
  })
}
