// Designer FITB field helpers
export function startBlankDragging(event, blank) {  
  event.dataTransfer.setData('draggable', blank.id);

  let marker = null;
  const editorElement = blank.closest('.designer__field').querySelector("textarea[data-controller='froala']")
  const editor = editorElement['data-froala.editor']

  setTimeout(() => {
    blank.closest('.designer__field').querySelector('.designer__field__editable-label--description:not(.designer__field__editable-label--active) .field__editable-label.fr-view')?.click()
  })

  const createMarker = () => {
    marker = document.createElement('span');
    marker.classList.add('drag-marker');
    return marker;
  }

  marker = createMarker();

  function removeMarker() {
    marker.remove()
    marker = null;
  }

  function moveMarker(range) {
    if (!editor.el.contains(range.commonAncestorContainer) || marker?.contains(range.startContainer)) return;

    try {
      range.insertNode(marker)
    } catch (e) { 
      return;
    }
  }

  const dragendHandler = (event) => {
    removeMarker()
    disconnectEvents()
  }

  const dragoverHandler = throttle((event) => {
    event.preventDefault()

    let range;

    if (document.caretPositionFromPoint) { // Firefox
      const caretPos = document.caretPositionFromPoint(event.clientX, event.clientY);
      if (caretPos) {
        range = document.createRange();
        range.setStart(caretPos.offsetNode, caretPos.offset);
        range.collapse(true);
      }
    } else if (document.caretRangeFromPoint) {
      range = document.caretRangeFromPoint(event.clientX, event.clientY);
    }

    if(range) moveMarker(range)
  }, 100);


  const dropHandler = (dropEvent) => {
    if (dropEvent.dataTransfer.getData('draggable')) {
      const draggable = document.getElementById(dropEvent.dataTransfer.getData('draggable'))
      const newDraggable = draggable.querySelector('input[type="text"]').cloneNode(true)

      newDraggable.dataset.url = `${newDraggable.dataset.url}`
      newDraggable.placeholder = newDraggable.dataset.editorPlaceholder;

      const editorMarker = editor.$el.find('.fr-marker')

      if (marker.parentNode) { 
          marker.parentNode.insertBefore(newDraggable, marker)        
      } else {
        editor.markers.insertAtPoint(dropEvent)

        throttle(() => {
          editorMarker.parentNode.insertBefore(newDraggable, editorMarker)
        }, 0)  
      }
    }

    return false;    
  }

  const disconnectEvents = () => {
    editor.el.parentElement.removeEventListener('dragover', dragoverHandler)
    editor.el.parentElement.removeEventListener('drop', dropHandler)
    blank.removeEventListener('dragend', dragendHandler)
  }

  editor.el.parentElement.addEventListener('dragover', dragoverHandler)
  editor.el.parentElement.addEventListener('drop', dropHandler)
  blank.addEventListener('dragend', dragendHandler)
}

function throttle(func, limit) {
  let lastFunc;
  let lastRan;
  return function (...args) {
    const context = this;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
}
