// Designer Helper
import { Droppable, Plugins } from '@shopify/draggable';
import { post } from "@rails/request.js"

import { autoUpdate, computePosition, offset } from '@floating-ui/dom';

export function init(){
  const designerSidebar = document.getElementsByClassName('designer__sidebar')[0]
  
  if(!designerSidebar) return

  designerSidebar.classList.remove('d-none')
  designerSidebar.style.visibility = 'hidden'
  designerSidebar.style.position = 'absolute'
  designerSidebar.style.top = '100vh'

  initFieldDragging()

  // Listen for custom event designer:toggle-field-input to toggle field input on body
  document.body.addEventListener('designer:toggle-field-input', toggleFieldInputEventListener)
  document.body.addEventListener('designer:toggle-section-input', toggleSectionInputEventListner)
}

export function toggleAddFields() {
  document.querySelectorAll(".designer__sidebar__tab").forEach((tab: HTMLElement) => {
    tab.style.display = 'none'
  })

  const sidebar_fields = document.getElementById("sidebar_fields")
  sidebar_fields.style.display = 'block'
}

export function toggleSidebar(event, type, tabDatabURL) {
  const skipClassName = '.designer__field--skip-toggle-sidebar'

  if (event.target.closest('.designer__field__actions')) return

  if (event.target.classList.contains(skipClassName) || event.target.closest(skipClassName)) {
    const newTabDatabURL = event.target.dataset['tabDataUrl'] || event.target.closest(skipClassName).dataset['tabDataUrl']

    if (!newTabDatabURL) return;
    
    tabDatabURL = newTabDatabURL
  }

  const tab = document.querySelector(`.designer__sidebar__tab[data-type="${type}"]`)

  if (type == 'fields') {
    tab.parentElement.classList.remove('bg-white')
    tab.parentElement.classList.add('bg-gray-50')
  } else {
    tab.parentElement.classList.remove('bg-gray-50')
    tab.parentElement.classList.add('bg-white')
  }

  Array.from(document.getElementsByClassName('designer__sidebar__tab')).forEach(elem => {
    elem.style.display = elem.dataset['type'] == type ? 'block' : 'none'
  })

  toggleActiveDesignerFieldsInSection(event.target)

  const designer = document.getElementById('designer')

  const frame = document.getElementById('field_settings_frame')

  if (tabDatabURL && tabDatabURL != "close") {
    if (frame.getAttribute('src') == tabDatabURL) return

    frame.setAttribute('src', tabDatabURL)

    if (designer.classList.contains('designer--sidebar-opened')) return
  } else {
    frame?.removeAttribute('src')
  }

  if (type == 'fields' && designer.classList.contains("designer--sidebar-opened") && tabDatabURL != "close") {
    toggleAddFields();
    return;
  }

  if (tabDatabURL && tabDatabURL == "close") {
    designer.classList.remove('designer--sidebar-opened')
  } else {
    designer.classList.add('designer--sidebar-opened')
  }

  const opening = designer.classList.contains('designer--sidebar-opened')

  const designerSidebar = document.getElementsByClassName('designer__sidebar')[0]
  if (opening) {
    // Show the sidebar before the animation starts
    designerSidebar.style.visibility = 'visible';
    designerSidebar.style.position = 'static';
    designerSidebar.style.top = '';
  }

  setTimeout(() => {
    // Only if window is bigger than 768px
    if (window.innerWidth <= 768) {
      document.getElementsByClassName('designer__sidebar')[0].style = opening ? 'width: 100%; z-index: 3000' : ''
    } else {
      const canvas = document.getElementsByClassName('designer__canvas')[0]

      if (opening) {
        canvas.classList.remove('col-12')
        canvas.classList.add('col')

      } else {
        canvas.classList.remove('col')
        canvas.classList.add('col-12')  
      }
    }

  }, opening ? 0 : 300)

  // Hide the sidebar after the animation ends
  if (!opening) {
    setTimeout(() => {
      designerSidebar.style.visibility = 'hidden';
      designerSidebar.style.position = 'absolute';
      designerSidebar.style.top = '100vh';
    }, 400)
  }
}

export function toggleSectionInput(event) {
  const label = event.closest('.designer__sidebar__item')
  
  label.classList.toggle('designer__sidebar__item--input-active')

  const input = label.querySelector('input')
  
  if(!input) return

  input.focus()
  input.setSelectionRange(input.value.length, input.value.length)
}

export function toggleFieldFocus(event, type, tabDatabURL) {
  toggleSidebar(event, type, tabDatabURL, undefined) 
  toggleFieldInput(event.target)
}

export function toggleFieldInput(event) {
  const label = event.closest('.designer__field__editable-label')

  if (!label) return

  label.classList.toggle('designer__field__editable-label--active')

  const input = label.querySelector('input') || label.querySelector('textarea')

  if(!input) return

  if (input.classList.contains('froala-editor')) {
    let event = new Event("focus");
    input.dispatchEvent(event);
  } else {
    if (input.tagName.toLowerCase() === 'textarea')
      input.style.height = input.scrollHeight + 'px';

    input.focus()
    input.setSelectionRange(input.value.length, input.value.length)  
  }

  intTabLinksKeyboardNavigation(input)
}

// Toggle field input on event listener
export function toggleFieldInputEventListener(event) {
  const id = event.detail?.id
  const editableLabel = document.getElementById(id).querySelector('.field__editable-label') as HTMLElement;
  if (editableLabel == null) return;

  editableLabel.click()
}

export function toggleSectionInputEventListner(event) {
  const id = event.detail?.id
  const editableLabel = document.getElementById(id).querySelector('.designer__sidebar__item__actions button') as HTMLElement;
  if (editableLabel == null) return;

  editableLabel.click()
}

export function toggleTabEventListener(event) {
  const ulElement = event.closest('ul')

  Array.from(ulElement.children).forEach(li => {
    const editableLabel = li.querySelector('.field__editable-label')
    if(editableLabel == null) return;

    if (li.classList.contains('active')) {
      editableLabel.setAttribute('onclick', 'Helpers.Designer.toggleFieldInput(this)');
    } else {
      editableLabel.setAttribute('onclick', 'Helpers.Designer.toggleTabEventListener(this)');
    }
  })
}

export function initFieldDragging() {
  const designer = document.getElementById('designer')
  const canvas = document.getElementsByClassName('designer__canvas')[0]

  const droppable = new Droppable([designer], {
    draggable: '.designer__fields-dragging__item',
    dropzone: '.designer__canvas__dropping-field-zone',
    mirror: {
      constrainDimensions: true,
      appendTo: 'main', // Append mirror element to main container
    },
  });

  droppable.on('drag:start', () => canvas.classList.add('designer__canvas--dragging'))

  droppable.on('droppable:dropped', (evt) => {
    if (!evt.dropzone.dataset['dropzone']) evt.cancel();
 
    // Remove class 'designer__canvas__dropping-field-zone--occupied' to the dropzone field
    evt.dropzone.classList.add('designer__canvas__dropping-field-zone--occupied')

    // Remove display none from the original source
    evt.data.dragEvent.data.originalSource.style.display = ''
    // Add class 'draggable-source--is-dragging' to the original source
    evt.data.dragEvent.data.originalSource.classList.add('draggable-source--is-dragging')

    document.querySelectorAll('.section-field .designer__canvas__dropping-field-zone.draggable-dropzone--occupied').forEach((i) => i.classList.remove('draggable-dropzone--occupied'))
  });

  // Droppable stop event
  droppable.on('droppable:returned', (evt) => {
    // Hide the original source
    evt.data.dragEvent.data.originalSource.style.display = 'none'
    // Remove class 'draggable-source--is-dragging' from the original source
    evt.data.dragEvent.data.originalSource.classList.remove('draggable-source--is-dragging')
    // Remove class 'designer__canvas__dropping-field-zone--occupied' from the dropzone field
    evt.dropzone.classList.remove('designer__canvas__dropping-field-zone--occupied')
  });

  droppable.on('droppable:stop', (evt) => {
    // Show the original source
    evt.data.dragEvent.data.originalSource.style.display = ''
    // Remove class 'draggable-source--is-dragging' from the original source
    evt.data.dragEvent.data.originalSource.classList.remove('draggable-source--is-dragging')

    canvas.classList.remove('designer__canvas--dragging')

    // Remove class 'designer__canvas__dropping-field-zone--occupied' from the dropzone field
    evt.dropzone.classList.remove('designer__canvas__dropping-field-zone--occupied')

    if(!evt.dropzone.dataset['dropzone']) return;

    const url = document.getElementById('create_field_path')?.value

    if (!url) return;

    const body = JSON.stringify({ type: evt.data.dragEvent.data.originalSource.dataset.type,
                                  layout: evt.data.dragEvent.data.originalSource.dataset.layout,
                                  position: evt.dropzone.dataset.dropzone })

    post(url, { body: body, responseKind: "turbo-stream" }).then(() => {
      const item = document.querySelector('.designer__canvas .designer__canvas__dropping-field-zone .designer__fields-dragging__item')
      const sidebarDropZone = document.querySelector('.designer__sidebar .designer__canvas__dropping-field-zone:not(.draggable-dropzone--occupied)')

      sidebarDropZone.append(item)
      item.parentElement.classList.add('draggable-dropzone--occupied')

      evt.cancel();

      const event = new CustomEvent("fetch-request:completed")
      document.dispatchEvent(event)
    });
  });
}

export function toggleNumberOfCorrectAnswersInput() {
  const container = document.getElementById('number_of_correct_answers_container')

  container.style.display = container.style.display == 'none' ? 'flex' : 'none';
}

export function toggleDragger(btn, dragger = 'row'){
  const table = btn.closest('.dynamic-table')
  const clicked = dragger == 'row' ? btn.closest('tr') : btn.closest('th')
  const clickedIsActive = clicked.classList.contains(`dynamic-table__${dragger}--focused`)

  table.querySelectorAll('.dynamic-table__row--focused').forEach(r => {
    r.classList.remove('dynamic-table__row--focused')
    r.removeAttribute('data-controller')
  })
  table.querySelectorAll('.dynamic-table__column--focused').forEach(c => {
    c.classList.remove('dynamic-table__column--focused')
  })  

  if (clickedIsActive) {
    clicked.classList.remove(`dynamic-table__${dragger}--focused`)
    clicked.removeAttribute('data-controller')
  } else {
    if (dragger == 'column') clicked.style.setProperty('--dynamic-table__column-height', `${table.offsetHeight - 58}px`)
    if (dragger == 'row') clicked.dataset.controller = 'compute-position'
    clicked.classList.add(`dynamic-table__${dragger}--focused`)
  }
}

export function toggleFieldTableRowColumn(btn){
  const clickedTableActions = event.relatedTarget && (event.relatedTarget.closest('.dynamic-table__actions') || event.relatedTarget.closest('.dynamic-table__row__handle') || event.relatedTarget.closest('#designer_field_table_cell_delete_modal'))
  if (clickedTableActions) {
    return
  } 

  const table = btn.closest('.dynamic-table')

  setTimeout(() => {
    table.querySelectorAll('.dynamic-table__row--focused').forEach(r => {
      r.classList.remove('dynamic-table__row--focused')
      r.removeAttribute('data-controller')
    })
    table.querySelectorAll('.dynamic-table__column--focused').forEach(c => {
      c.classList.remove('dynamic-table__column--focused')
    })    
  }, 200);
}

export function toggleActiveDesignerFieldsInSection(element) {
  const sectionFields = document.getElementById('section-fields');
  if (!sectionFields) return;

  const eventSectionField = element.closest('.section-field');

  Array.from(sectionFields.children).forEach(elem => {
      const designerField = elem.querySelector('.designer__field');
      if (!designerField) return;

      if (elem === eventSectionField) {
        designerField.classList.add('designer__field--active');
      } else {
        designerField.classList.remove('designer__field--active');
      }
  });
}

export function toggleHideInReport(sectionFieldId) {
  const sectionField = document.getElementById(sectionFieldId);
  if (!sectionField) return;

  const desginerField = sectionField.querySelector('.designer__field')
  if (!desginerField) return;
  
  desginerField.classList.toggle('designer__field__hide-in-report--active')
}

export function fitbAddAlternative(btn){
  const form = btn.closest('form')

  form.setAttribute('data-controller', '');

  const alternativesContainer = form.querySelector('.fitb_modal__alternatives')
  const type = alternativesContainer.classList.contains('fitb_modal__alternatives--dropdown') ? 'dropdown' : 'blank';
  const newInputGroup = alternativesContainer.querySelector(`.fitb_modal__alternatives__item:first-of-type`).cloneNode(true)

  if (type == 'blank') newInputGroup.querySelector('label').remove()

  const newInputHidden = newInputGroup.querySelectorAll('input[type="hidden"]')
  const newInputText   = newInputGroup.querySelector('input[type="text"]')
  const newItemIndex   = alternativesContainer.querySelectorAll('.fitb_modal__alternatives__item').length + 1

  newInputHidden[0].name  = `fitb_data[alternatives][${newItemIndex}][uuid]`;
  const uuid = Array.from(crypto.getRandomValues(new Uint8Array(6)), byte => byte.toString(16).padStart(2, '0')).join('');
  newInputHidden[0].value = uuid;

  newInputHidden[1].name = `fitb_data[alternatives][${newItemIndex}][position]`;

  newInputText.name   = `fitb_data[alternatives][${newItemIndex}][text]`;
  newInputText.value  = ''

  if (type == 'dropdown') {
    const reflectorID = `designer__field__title--${newItemIndex}`
    const label = newInputGroup.querySelector('.field__editable-label')
    label.id = reflectorID
    label.innerHTML = 'Enter option title here'
    label.className = ''
    label.className = `field__editable-label px-1 ${reflectorID}`

    newInputText.dataset['controller'] = ''
    newInputText.dataset['reflectorSelector'] = reflectorID
    newInputText.dataset['inputReflectorSelectorValue'] = reflectorID
    newInputText.dataset['controller'] = 'input-reflector'

    const newInputRadio = newInputGroup.querySelector('input[type="radio"]')
    newInputRadio.value = uuid;
  }

  alternativesContainer.appendChild(newInputGroup)

  setTimeout(() => form.setAttribute('data-controller', 'validate-form'), 50)
  setTimeout(() => newInputText.focus(), 500)
}

export function fitbRemoveAlternative(btn) {
  const form = btn.closest('form')

  form.setAttribute('data-controller', '');

  btn.closest('.fitb_modal__alternatives__item').remove()

  setTimeout(() => form.setAttribute('data-controller', 'validate-form'), 50)
}

// Keyboard navigation check for tab to prevent default bootstrap nav links events
function intTabLinksKeyboardNavigation(input) {
  let tabNav = document.querySelector('[data-bs-toggle="tab"]');
  if (tabNav == null) return;

  const arrowKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
  
  input.addEventListener('keydown', (e) => {
    if (arrowKeys.includes(e.key)) {
      e.stopImmediatePropagation();
    }
  });
}
