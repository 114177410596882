export const CAStreams = {
  update_attributes() {
    const elements = document.querySelectorAll(this.getAttribute("selector"));
    const name = this.getAttribute("name");
    const value = this.getAttribute("value");

    elements.forEach(el => {
      el.setAttribute(name, value);
    });
  },

  remove_classes() {
    const elements = document.querySelectorAll(this.getAttribute("selector"));
    const classes = this.getAttribute("classes");

    elements.forEach(el => {
      el.classList.remove(classes)
    })
  }
};

export function initializeCustomActions(streamActions) {
  Object.assign(streamActions, CAStreams);
}
