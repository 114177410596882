import { get } from '@rails/request.js'

export function generateActivityCode() {
  const target = event.target
  const activityCodeInput = document.getElementById('activity_template_code__input')
  const activityCodeInputPlaceholder = activityCodeInput.placeholder

  if (!target.value || activityCodeInput.value) return;

  activityCodeInput.disabled = true;
  activityCodeInput.parentElement.classList.add('input-group--disabled')
  activityCodeInput.placeholder = 'Generating activity code...'

  get(target.dataset['suggestionUrl'], { query: { title: (target as HTMLInputElement).value } }).then((result) => {
    result.response.json().then((data: any) => activityCodeInput.value = data['result'])
    document.getElementById('activity_template_code__label').style.display = 'block'

    activityCodeInput.disabled = false;
    activityCodeInput.parentElement.classList.remove('input-group--disabled')
    activityCodeInput.placeholder = activityCodeInputPlaceholder
  })
}

export function loadGradingSchemeThreshold() {
  const container = document.querySelector('.grading__satisfactory-threshold-container__dropdowns');
  const firstLevelDropdowns = container ? Array.from(container.children).filter(child => !child.hasAttribute('style')) : [];
  const previous = document.getElementById(firstLevelDropdowns[0].id)
  const selected = document.getElementById(`grading_scheme_satisfactory-threshold--${event.target.value}`)
  const selectedSelect = selected.querySelector('select')

  previous.style.display = 'none'

  const selectedName = selectedSelect.getAttribute('name')
  previous.querySelector('select').setAttribute('name', selectedName)

  selected.removeAttribute('style');
  selectedSelect.setAttribute('name', 'revision_satisfactory_threshold');
}

export function contentCreationChecked() {
  disableWeightedQuestionScoring()

  reloadCompletitionBehaviour()

  reloadActivityToBeCompletedByBehaviour()
}

function disableWeightedQuestionScoring() {
  const field = document.getElementById('revision_weighted_question_scoring')

  if (!field) return;

  if (event.target.value == 'scorm') {
    field.checked = false;
    field.disabled = true;
  } else {
    field.disabled = false;
  }
}

export function reloadCompletitionBehaviour(){
  const completitionBehaviourChoices = document.getElementById('revision__completition_behaviour_type').choices._choices

  if (event.target.value != 'scorm') {
    completitionBehaviourChoices.setChoices([ { value: 'repeat_upon_submission', label: 'Repeat upon submission' } ], 'value', 'label', false)
  } else {
    const selected = completitionBehaviourChoices.getValue(true)

    completitionBehaviourChoices.removeChoice('repeat_upon_submission')

    if (selected == 'repeat_upon_submission') completitionBehaviourChoices.setChoiceByValue('no_action')
  }
}

export function reloadActivityToBeCompletedByBehaviour(){
  const activityToBeCompletedBy = document.getElementById('activity_to_be_completed_by')

  if (event.target.value == 'scorm') {
    activityToBeCompletedBy.choices.disable()
    activityToBeCompletedBy.closest('.input-group').classList.add('input-group--disabled')
  } else {
    activityToBeCompletedBy.choices.resetChoices();
    activityToBeCompletedBy.closest('.input-group').classList.remove('input-group--disabled')
  }
}


export function activityToBeCompletedByChanged(){
  const completitionBehaviour = document.getElementById('revision__completition_behaviour_type')
  const revisionExpiryDuration = document.getElementById('revision_expiry_duration')
  const scromContentCreationInput = document.getElementById('scrom_content_creation').querySelector('input')

  if (event.target.value == 'third_party') {
    completitionBehaviour.choices._choices.setChoiceByValue('no_action')
    completitionBehaviour.choices.disable()
    completitionBehaviour.closest('.input-group').classList.add('input-group--disabled')

    revisionExpiryDuration.classList.add('d-none')

    scromContentCreationInput.disabled = true
  } else {
    completitionBehaviour.choices.resetChoices();
    completitionBehaviour.closest('.input-group').classList.remove('input-group--disabled')

    scromContentCreationInput.disabled = false
  }
}
