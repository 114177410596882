import Libraries from "./libraries"

export default class CloudAssessApp {
  private _libraries: Libraries

  constructor() {
    this._libraries = new Libraries
  }
}

