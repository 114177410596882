//
// This file is a fork from https://raw.githubusercontent.com/hotwired/stimulus-rails/7e0150bc2f30f8443c8bfdbfdd508c2eea4427e7/app/assets/javascripts/stimulus-loading.js.
//
// As the stimulus-rails gem requires 'import maps' we are not able to use this gem.
// I've just forked the file to be able to lazy load controllers, so woud be nice sometimes have a look on the repo to 
// check for any updates.
// 
// My changes on this file:
//   1. Removed the unneeded methods (eagerLoadControllersFrom, parseImportmapJson and registerControllerFromPath)
//   2. Changes the `controllerFilename` method to our context.
//   3. Remove under variable
//   4. Using Vite import glob
//

const modules = import.meta.glob('./controllers/*.ts')

const controllerAttribute = "data-controller"
const skippedControllers = ['']

export function lazyLoadControllersFrom(application, element = document) {
  lazyLoadExistingControllers(application, element)
  lazyLoadNewControllers(application, element)
}

function lazyLoadExistingControllers(application, element) {
  queryControllerNamesWithin(element).forEach(controllerName => loadController(controllerName, application))
}

function lazyLoadNewControllers(application, element) {
  new MutationObserver((mutationsList) => {
    for (const { attributeName, target, type } of mutationsList) {
      switch (type) {
        case "attributes": {
          if (attributeName == controllerAttribute && target.getAttribute(controllerAttribute)) {
            extractControllerNamesFrom(target).forEach(controllerName => loadController(controllerName, application))
          }
        }

        case "childList": {
          lazyLoadExistingControllers(application, target)
        }
      }
    }
  }).observe(element, { attributeFilter: [controllerAttribute], subtree: true, childList: true })
}

function queryControllerNamesWithin(element) {
  const controllers = element.querySelectorAll(`[${controllerAttribute}]`);
  const skipppedSelectors = skippedControllers.map(c => `[${controllerAttribute}="${c}"]`).join(',');
  const nonSkippedControllers = Array.from(controllers).filter(el => !el.matches(skipppedSelectors));

  return nonSkippedControllers.map(extractControllerNamesFrom).flat()
}

function extractControllerNamesFrom(element) {
  return element.getAttribute(controllerAttribute).split(/\s+/).filter(content => content.length)
}

function loadController(name, application) {
  if (canRegisterController(name, application)) {
    modules[`${controllerFilename(name)}.ts`]()
        .then(module => registerController(name, module, application))
        .catch(error => console.error(`Failed to autoload controller: ${name}`, error))
  }
}

function controllerFilename(name) {
  return `./controllers/${name}`
}

function registerController(name, module, application) {
  if (canRegisterController(name, application)) {
    application.register(name, module.default)
  }
}

function canRegisterController(name, application){
  return !application.router.modulesByIdentifier.has(name)
}
