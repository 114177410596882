import { Turbo } from "@hotwired/turbo-rails"
import { Application } from '@hotwired/stimulus'
import TurboPower from 'turbo_power'

import { lazyLoadControllersFrom } from './stimulus/loader'
import { Dropdown, Tab, Carousel } from 'bootstrap'

import '@popperjs/core'

import { BrowserUserAgent } from './libs/user_agent'
import { TooltipsListener } from './libs/tooltips_listener'
import { initializeCustomActions } from './libs/ca_streams'

TurboPower.initialize(Turbo.StreamActions)

initializeCustomActions(Turbo.StreamActions)

export default class Libraries {
  constructor() {
    this._initTurbo()

    this._initStimulus()

    this._initBootstrap()

    this._initBrowserUserAgent()
  }

  private _initTurbo(){
    Turbo.start()
    Turbo.session.drive = false
  }

  private _initStimulus(){
    const application = Application.start()
    lazyLoadControllersFrom(application)

    // Hack to re-open offcanvas
    document.body.addEventListener("offcanvas:open", (event) => {
      setTimeout(() => {
        application.getControllerForElementAndIdentifier(document.querySelector("[data-controller='turbo-form-offcanvas']"), 'turbo-form-offcanvas').open(event.detail.title, event.detail.source, event.detail.size, event.detail.tableAll, event.detail.tableIds, event.detail.bridgeElement)
      }, 500)
    })
  }

  private _initBootstrap() {
    Dropdown
    Tab
    Carousel

    new TooltipsListener();
  }

  private _initBrowserUserAgent() {
    const browserUserAgent = new BrowserUserAgent()
    document.body.dataset.browser = browserUserAgent.getBrowser()
  }
}
